<template>
  <div>
    <div class="mb-3">
      <div class="btn-back" @click="backToPlanPreference">
        <img src="@/assets/Icons/arrow-left-line.svg" alt="back" height="35" />
      </div>
    </div>
    <w-digital-planning-card-component title="Available Assets*" />
    <div class="card dp-card">
      <div class="row p-2">
        <div
          class="pt-1 pb-1 mb-1 w-50"
          v-for="(asset, index) in assets"
          :key="index"
        >
          <input
            type="checkbox"
            :name="asset.name"
            :value="asset.value"
            :id="asset.value"
            :checked="asset.checked"
            @click="selectAssets($event, asset.value)"
          />
          <label :for="asset.value" class="dp-label">{{ asset.name }}</label>
        </div>
        <h1 class="heading d-none">Prefered Publication Language*</h1>
        <div>
          <div class="pt-4" v-for="(language, index) in languages" :key="index">
            <input
              type="checkbox"
              :name="language.value"
              :id="language.value"
              :value="language.value"
              :checked="language.checked"
              @click="selectLanguage($event, language.name)"
            />
            <label :for="language.value" class="dp-label">{{
              language.name
            }}</label>
            <br />
          </div>
        </div>
        <div class="body mb-3">
          <div class="mt-4">
            <span class="heading">Impact Properties </span>
            <hr />
          </div>
          <div class="row">
            <div class="button-grp">
              <button
                v-for="(button, index) in buttons"
                :key="index"
                class="card1 dp-card mt-5 ml-3 text-black"
                :class="{ active: button.value }"
                @click="showRequired(button)"
              >
                {{ button.name }}
              </button>
            </div>
          </div>
        </div>
        <div
          class="body mb-3"
          :style="isActive ? 'display: block' : 'display: none'"
        >
          <div class="p-3">
            <span class="heading"
              >Enter Prefered Budget*
              <span
                style="font-size: 14px;
    font-family: 'Muli',sans-serif;
    color: rgb(217, 46, 129);
}"
                v-if="budgetPercentage === 100"
              >
                You have given 100% of your budget to impact properties</span
              ></span
            >
          </div>
          <div class="row pl-5 pr-5">
            <div class="w-100 ml-5">
              <label class="pl-2 mr-5 dp-label"> Budget : </label>
              <input
                class="budget-card"
                type="number"
                :max="100"
                :min="0"
                @keypress="avoidDecimal"
                style="width: 13%"
                v-model="budgetPercentage"
                @change="setBudget($event)"
              />
              <label class="ml-3 text dp-label"> %</label>
            </div>
          </div>
        </div>
        <div class="body">
          <div class="p-3">
            <span class="heading"
              >Which Network channels you have access to*
            </span>
            <hr />
          </div>
          <div class="row pl-5 pr-5">
            <div
              class="pt-1 pb-1 mb-1 w-50"
              v-for="(network, index) in networks"
              :key="index"
            >
              <input
                type="checkbox"
                :name="network.name"
                :value="network.value"
                :id="network.value"
                :checked="network.checked"
                @click="selectNetwork($event, network.value)"
              />
              <label :for="network.value" class="dp-label text pr-4 pl-2">{{
                network.name
              }}</label>
              <br />
            </div>
          </div>
        </div>
      </div>
      <center>
        <button class="submit-button" @click="handalNextBrand">
          Continue
        </button>
      </center>
      <!--<div class="w-50">
        <button class="btn btn-danger" @click="backToPlanPreference">
          Back
        </button>
      </div>-->
    </div>
  </div>
</template>

<script>
import DigitalPlanningComponentCard from "@/components/DigitalPlanning/components/DigitalPlanningCardComponent";

export default {
  components: {
    "w-digital-planning-card-component": DigitalPlanningComponentCard,
  },
  created() {
    for (var i = 0; i < this.assets.length; i++) {
      this.selectedAssets.push(this.assets[i].value);
    }
    // for(var j=0; j<this.networks.length; j++){
    //   this.selectedNetwork.push(this.networks[j].value)
    // }
  },
  data() {
    return {
      buttons: [
        { name: "Required", value: false },
        { name: "Not Required", value: true },
      ],
      assets: [
        { name: "Text Ads", value: "text ads", checked: true },
        { name: "Display Ads", value: "display ads", checked: true },
        { name: "Video Ads", value: "video ads", checked: true },
        { name: "Native Ads", value: "native ads", checked: true },
        { name: "Social Posts", value: "social posts", checked: true },
        { name: "E-Mailers", value: "emailers", checked: true },
      ],
      networks: [
        { name: "DV360", value: "dv360", checked: false },
        { name: "Taboola", value: "taboola", checked: false },
        { name: "Outbrain", value: "outbrain", checked: false },
        { name: "Appnexus", value: "appnexus", checked: false },
        { name: "Amazon", value: "amazon", checked: false },
        { name: "Mediamath", value: "mediamath", checked: false },
        { name: "Rocket Fuel", value: "rocket fuel", checked: false },
        { name: "Sizmek", value: "sizmek", checked: false },
        { name: "Colombia", value: "colombia", checked: false },
        {
          name: "Oath Native & Search",
          value: "oath native & search",
          checked: false,
        },
        { name: "Revcontent", value: "revcontent", checked: false },
      ],
      languages: [
        // { name: "Hindi", value: "hindi", checked: false },
        // { name: "English", value: "english", checked: false },
        // { name: "Bengali", value: "bengali", checked: false },
        // { name: "Marathi", value: "marathi", checked: false },
        // { name: "Tamil", value: "Tamil", checked: false },
        // { name: "Kannada", value: "Kannada", checked: false },
        // { name: "Gujarati", value: "Gujarati", checked: false },
        // { name: "Telugu", value: "Telugu", checked: false },
        // { name: "Malayalam", value: "Malayalam", checked: false },
        // { name: "No Preference", value: "No Preference", checked: true },
      ],
      isActive: false,
      selectedLanguage: ["No Preference"],
      selectedAssets: [],
      budgetPercentage: 0,
      selectedNetwork: [],
    };
  },
  methods: {
    avoidDecimal(event) {
      if (event.charCode >= 48 && event.charCode <= 57) {
        return true;
      } else {
        event.preventDefault();
      }
    },
    setBudget(event) {
      if (event.target.value > 0 && event.target.value < 100) {
        this.budgetPercentage = event.target.value;
      } else if (event.target.value <= 0 || "") {
        this.budgetPercentage = 0;
      } else if (event.target.value > 100) {
        this.budgetPercentage = 100;
      }
    },
    selectLanguage(event, language) {
      if (event.target.checked) {
        this.selectedLanguage.push(language);
      } else {
        this.selectedLanguage = this.selectedLanguage.filter(
          (value) => value !== language
        );
      }
    },
    selectAssets(event, asset) {
      if (event.target.checked) {
        this.selectedAssets.push(asset);
      } else {
        this.selectedAssets = this.selectedAssets.filter(
          (value) => value !== asset
        );
      }
      if (asset === "display ads") {
        this.setimpact();
      }
    },
    setimpact() {
      if (this.selectedAssets.includes("display ads")) {
        this.buttons = [
          { name: "Required", value: false },
          { name: "Not Required", value: true },
        ];
      } else {
        this.buttons = [{ name: "Not Required", value: true }];
      }
      this.isActive = false;
    },
    selectNetwork(event, network) {
      if (event.target.checked) {
        this.selectedNetwork.push(network);
        for (var i = 0; i < this.networks.length; i++) {
          if (this.networks[i].value === network) {
            this.networks[i].checked = true;
          }
        }
      } else {
        this.selectedNetwork = this.selectedNetwork.filter(
          (value) => value !== network
        );
        for (var j = 0; j < this.networks.length; j++) {
          if (this.networks[j].value === network) {
            this.networks[j].checked = false;
          }
        }
      }
    },
    handalNextBrand() {
      var availableAssetData = {};
      var notSelectedNetworks = [];
      for (var i = 0; i < this.networks.length; i++) {
        if (!this.networks[i].checked) {
          notSelectedNetworks.push(this.networks[i].value);
        }
      }

      if (
        this.selectedAssets.length === 0 ||
        this.selectedLanguage.length === 0
      ) {
        return true;
      }
      if (!this.isActive) {
        availableAssetData = {
          assetsAvailable: this.selectedAssets,
          publicationLanguage: this.selectedLanguage,
          impactRequired: this.isActive,
          websitesNotRequired: notSelectedNetworks,
          //planName: "default",
          //userName: sessionStorage.getItem("clientId"),
        };
      } else {
        availableAssetData = {
          assetsAvailable: this.selectedAssets,
          publicationLanguage: this.selectedLanguage,
          impactRequired: this.isActive,
          websitesNotRequired: notSelectedNetworks,
          impactBudget: parseInt(this.budgetPercentage),
          //planName: "default",
          //userName: sessionStorage.getItem("clientId"),
        };
      }
      this.$emit("handalNextBrand", availableAssetData);
    },
    backToPlanPreference() {
      this.$emit("backToPlanPreference", true);
    },
    showRequired(tab) {
      this.buttons.forEach((button) => {
        if (button.name === tab.name) {
          button.value = true;
        } else {
          button.value = false;
        }
        if (tab.name == "Required") {
          this.isActive = true;
        } else {
          this.isActive = false;
        }
      });
    },
  },
};
</script>

<style scoped>
.btn-group {
  color: #fff;
  background: inherit;
  font-size: 14px;
  padding: 6px 15px;
  cursor: pointer;
  border: 1px solid;
}

.active {
  background: #20a8d8;
  border: 1px solid;
  pointer-events: none;
}
.wrapper {
  border: 1px solid;
  border-radius: 10px;
  background-color: #3c4c66;
}
input[type="number"] {
  padding-left: 5px;
  border: 0px;
  border-radius: 5px;
  color: black;
  font-size: 14px;
  height: 30px;
}
.dp-card {
  padding: 2rem;
  width: 80vw;
  background-color: white;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-bottom: 50px;
}
.dp-label {
  font-size: 15px;
  margin: 1rem;
  color: black;
}
.heading {
  font-size: 24px;
  font-weight: bold;
  font-family: ProximaNovaBold;
  color: black;
  margin-top: 2rem;
}
.card1 {
  background-color: #d92e72;
  color:#fff;
  margin-bottom: 8px;
  border: 0px;
  font-size: 14px;
  font-weight: bold;
}
.card1:hover {
opacity: 0.9;
}
.button-grp {
  display: flex;
  width: 400px;
}

.submit-button {
  margin-top: 40px;
  background: none;
  border-radius: 5px;
  color: #fff;
  background: #d92e72;
  display: block;
  align-items: center;
  font-weight: bold;
  border-radius: 8;
  border-width: 0;
  width: 200px;
  height: 50px;
  font-size: 18px;
}

.budget-card {
  background: #eff5ff;
  padding: 2rem;
  border-width: 0px;
}

.submit-button:hover {
opacity: 0.9;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
</style>
