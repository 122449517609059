<template>
  <div>
    <div class="mb-3">
      <div class="btn-back" @click="back">
        <img src="@/assets/Icons/arrow-left-line.svg" alt="back" height="35" />
      </div>
    </div>
    <w-digital-planning-card-component title="Summary of Input Data Sources" />
    <div class="card dp-card">
      <div class="mediaIcons" style="padding: 15px">
        <div
          class="row"
          v-for="(orderedListOfConnector,index) in orderedListOfConnectors"
          :key="index"
          :class="{ disable: orderedListOfConnector.connectors.length == 0 }"
        >
          <h2 class="title">{{ orderedListOfConnector.title }}</h2>
          <div
            class="col-lg-3 col-md-4 col-sm-6 col-xs-12 connectors"
            v-for="(connector,index) in orderedListOfConnector.connectors"
            :key="index"
          >
            <div class="iconBox active">
              <img class="icon" v-bind:src="connector.icon" />
              <span class="iconArea" :class="connector.className"></span>
              <span class="iconText" style="color:#fff">{{ connector.name }}</span>
              <span class="iconSelected">
                <i class="fa fa-check-circle" aria-hidden="true"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="next-btn">
        <button class="submit-button" @click="gotoLearning">Continue</button>
      </div>
    </div>
  </div>
</template>
<script>
import DigitalPlanningCardComponent from "@/components/DigitalPlanning/components/DigitalPlanningCardComponent";

export default {
  components: {
    "w-digital-planning-card-component": DigitalPlanningCardComponent,
  },
  data: function() {
    return {
      orderedListOfConnectors: [
        {
          connectors: [
            {
              className: "googleAd",
              name: "Google Ads",
              icon: require("@/assets/connectors/google-ad.png"),
            },
            {
              className: "facebook",
              name: "Facebook",
              icon: require("@/assets/connectors/Facebook.png"),
            },
            {
              className: "instagram",
              name: "Instagram",
              icon: require("@/assets/connectors/instagram.svg"),
            },
            {
              className: "twitter",
              name: "Twitter",
              icon: require("@/assets/connectors/twitter.png"),
            },
            {
              className: "program",
              name: "Dv360",
              icon: require("@/assets/connectors/program.png"),
            },
              {
              className: "similarweb",
              name: "Similar Web",
              icon: require("@/assets/connectors/similarweb.png"),
            },
            {
              className: "linkedin",
              name: "LinkedIn",
              icon: require("@/assets/connectors/LinkedIn.png"),
            },
            {
              className: "fbinsight",
              name: "Audience Insights",
              icon: require("@/assets/connectors/Facebook Page Insights.png"),
            },
            {
              className: "gkplanner",
              name: "Keyword Planner",
              icon: require("@/assets/connectors/google-ad.png"),
            },
            {
              className: "gTrends",
              name: "Google Trends",
              icon: require("@/assets/connectors/Google Trends.svg"),
            },
          ],
          title: "Digital Media",
        },
      ],
    };
  },
  methods: {
    back() {
      this.$emit("back", true);
    },
    gotoLearning() {
      this.$emit("gotoLearning", "go");
    },
  },
};
</script>
>
<style scoped>
.title {
  display: block;
  width: 100%;
  margin: 20px 10px 12px;
}
.mediaIcons .iconBox:not(.disabled) {
  background-color: #e9f0ff;
}
.mediaIcons .iconBox {
  width: 100%;
  background-color: transparent;
  border: 2px solid #fff;
  border-radius: 10px;
  display: flex;
  height: 45px;
  padding: 5px;
  margin: 5px 0;
  cursor: pointer;
}
.mediaIcons .iconBox .iconArea {
  width: 30px;
  height: 30px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 90%;
}
.mediaIcons .iconBox .iconText {
  padding: 4px 10px;
  width: 75%;
  align-self: center;
}

.mediaIcons .iconBox.active .iconSelected,
.mediaIcons .iconBox.connected .iconSelected,
.mediaIcons
  .iconBox:not(.disabled):hover
  .mediaIcons
  .iconBox.connected
  .iconSelected {
  color: #4aef49;
  align-self: center;
}
.mediaIcons .iconBox .iconSelected {
  font-size: 1.2rem;
}
.mediaIcons .iconBox.active .iconSelected,
.mediaIcons .iconBox.connected .iconSelected,
.mediaIcons .iconBox:not(.disabled):hover .iconSelected {
  display: inline-block;
}

.iconText {
  font-size: 14px;
  font-weight: 700;
}
.card {
  box-shadow: none;
  border-radius: 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2);
}
.card-header h4 {
  color: #d92e72;
  margin: 20px 0;
}
.card-header {
  border-bottom: 0 none;
  background: none;
}
.mediaIcons .iconBox.active {
  background: #d92e72;
  color: #1c2635;
  padding: 3rem;
  display: flex;
}
.row.disable {
  display: none;
}

.next-btn {
  display: grid;
  place-items: center;
}
.dp-card {
  padding: 2rem;
  width: 80vw;
  margin-bottom: 40px;
  background-color: white;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.next-btn span {
  color: #fff;
  background: #d92e72;
  border-radius: 12px;
  padding: 2px 20px;
  cursor: pointer;
  font-size: 14px;
}

.submit-button {
  margin-top: 40px;
  background: none;
  border-radius: 5px;
  color: #fff;
  background: #d92e72;
  display: block;
  font-weight: bold;
  border-radius: 8;
  border-width: 0;
  width: 200px;
  height: 50px;
  font-size: 18px;
}

.submit-button:hover {
opacity: 0.9;
}

.connectors {
  padding: 1rem;
}

.icon {
  align-self: center;
  width: 30px;
}
</style>
