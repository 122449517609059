<template>
  <div>
    <div class="mb-4">
      <div class="btn-back" @click="backToCampaignDetails">
        <img src="@/assets/Icons/arrow-left-line.svg" alt="back" height="35" />
      </div>
    </div>
    <w-digital-plaiing-card-component title="Plan Preference" />
    <div class="card dp-card">
      <div v-for="(plan, index) in planOption" :key="index">
        <div class="dp-card-body">
          <w-digital-planning-card
            :isActive="activeClass"
            :option="plan"
            @optionClick="getSelectedPlan($event)"
          />
        </div>
        <div class="dp-card-body">
          <div
            :style="
              planPreference === 'Define Budget'
                ? 'display: block'
                : 'display: none'
            "
          >
            <label for="budgetBased" class="heading">Enter The Budget* </label>
            <input
              class="budget-card"
              type="number"
              name="budgetBased"
              v-model="budgetBased"
              :step="1"
              :min="1000000"
              @keypress="avoidDecimal"
              @change="chnageInputValue($event)"
            />
            <span class="heading"
              >You have Entered
              <span style="font-weight:bold">{{ budgetBasedWords }}</span>
              Budget</span
            >
          </div>

          <div
            :style="
              planPreference === 'Objective Based'
                ? 'display: block'
                : 'display: none'
            "
          >
            <span>Select Objective* </span>
            <div v-for="(option, index) in objectiveBased" :key="index">
              <w-digital-planning-card
                :isActive="engage"
                :option="option"
                @optionClick="getSelectedOptionObjective($event)"
              />
            </div>
            <div
              :style="
                showObj === 'Inform & Engage'
                  ? 'display: block'
                  : 'display: none'
              "
            >
              <span>Select Budget or Reach* </span>
              <div v-for="(option, index) in informAndEngage" :key="index">
                <w-digital-planning-card
                  :isActive="objClass"
                  :option="option"
                  @optionClick="getSelectedOptionInform($event)"
                />
              </div>
            </div>
            <div
              :style="
                showInform === 'Reach' ? 'display: block' : 'display: none'
              "
            >
              <span
                >Select The Reach (<span style="font-size: 12px">in %</span
                >)*</span
              >
              <div v-for="(budget, index) in reach" :key="index">
                <input
                  type="radio"
                  name="budget"
                  :id="'engage' + budget"
                  :value="budget"
                  @click="selectEngageReach(budget)"
                />
                <label :for="'engage' + budget">{{ budget }}</label>
              </div>
            </div>
          </div>

          <div
            :style="
              showObj === 'Inform & Action' ? 'display: block' : 'display: none'
            "
          >
            <span>Select Budget or Reach* </span>
            <div v-for="(option, index) in informAndEngage" :key="index">
              <w-digital-planning-card
                :isActive="action"
                :option="option"
                @optionClick="getSelectedOptionInformAction($event)"
              />
            </div>
          </div>
          <div
            :style="
              showAction === 'Action-Reach' ? 'display: block' : 'display: none'
            "
          >
            <span
              >Select The Reach (<span style="font-size: 12px">in %</span
              >)*</span
            >
            <div v-for="(budget, index) in reach" :key="index">
              <input
                type="radio"
                name="Reach"
                :id="'action' + budget"
                :value="budget"
                @click="selectActionReach(budget)"
              />
              <label :for="'action' + budget">{{ budget }}</label>
            </div>
          </div>
          <div
            :style="
              showAction === 'Action-Budget'
                ? 'display: block'
                : 'display: none'
            "
          >
            <span
              >Select The Budget (<span style="font-size: 12px">in Lacs</span
              >)</span
            >
          </div>
          <div :style="showAllocation ? 'display: block' : 'display: none'">
            <div>
              <span>Budget Allocation*</span>
            </div>
            <div>
              <div>
                <div>
                  <label> Inform : </label>
                  <input type="number" />
                  <label> %</label>
                </div>
              </div>
              <div>
                <div>
                  <label> Action :</label>
                  <input type="number" />
                  <label> %</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     <center>
        <button class="submit-button" @click="nextToAssets">Continue</button>
      </center>
    </div>
    <div>
    </div>
  </div>
</template>

<script>
import DigitalPlanningCard from "@/widgets/DigitalPlanningCard.vue";
import DigitalPlanningComponentCard from "@/components/DigitalPlanning/components/DigitalPlanningCardComponent";
export default {
  components: {
    "w-digital-planning-card": DigitalPlanningCard,
    "w-digital-plaiing-card-component": DigitalPlanningComponentCard,
  },
  data() {
    return {
      planOption: [
        "Define Budget",
        //"Objective Based"
      ],
      budgetBased: 1000000,
      actionBudget: 1000000,
      engageBudget: 1000000,
      actionReach: "",
      engageReach: "",
      activeClass: "Define Budget",
      engage: "",
      objClass: "",
      showInform: "",
      informAndEngage: ["Reach", "Budget"],
      planPreference: "Define Budget",
      objectiveBased: ["Inform & Engage", "Inform & Action"],
      reach: ["<10", "10-30", "30-50", "50-70", "70"],
      budgets: [
        "<20",
        "20-30",
        "30-50",
        "50-100",
        "100-150",
        "150-200",
        "200-300",
      ],
      showObj: "",
      action: "",
      showAction: "",
      showAllocation: false,
    };
  },
  computed: {
    budgetBasedWords() {
      var value = require("num-words")(this.budgetBased);
      return value.toUpperCase();
    },
    actionBudgetWords() {
      var value = require("num-words")(this.actionBudget);
      return value.toUpperCase();
    },
    engageBudgetWords() {
      var value = require("num-words")(this.engageBudget);
      return value.toUpperCase();
    },
  },
  methods: {
    avoidDecimal(event) {
      if (event.charCode >= 48 && event.charCode <= 57) {
        return true;
      } else {
        event.preventDefault();
      }
    },
    setToDefaultBudget(event) {
      if (event.target.value <= 1000000 || "") {
        this.engageBudget = 1000000;
      }
      this.test(this.budgetBased);
    },
    chnageInputValue(event) {
      if (event.target.value <= 1000000 || "") {
        this.budgetBased = 1000000;
      }
    },
    chnageTodefault(event) {
      if (event.target.value <= 1000000 || "") {
        this.actionBudget = 1000000;
      }
    },
    backToCampaignDetails() {
      this.$emit("backToCampaignDetails", true);
    },
    selectBudgetBased(budgetBased) {
      console.log(budgetBased);
    },
    selectActionReach(reach) {
      this.actionReach = reach;
    },
    selectEngageReach(reach) {
      this.engageReach = reach;
    },
    nextToAssets() {
      var planData = {};
      var budget;
      var reach;
      if (this.planPreference === "") {
        return true;
      }
      if (this.planPreference === "Define Budget") {
        planData = {
          planPreference: this.planPreference,
          budget: this.budgetBased,
        };
        if (this.budgetBased === 0) {
          return true;
        }
        this.$emit("handalNextToAssets", planData);
      } else if (this.planPreference === "Objective Based") {
        if (this.showObj === "") {
          return true;
        }
        if (this.showObj === "Inform & Engage") {
          if (this.showInform === "Reach" && this.engageReach === "") {
            return true;
          } else if (this.showInform === "Budget" && this.engageBudget === "") {
            return true;
          }
          budget = this.engageBudget;
          reach = this.engageReach;
        } else if (this.showObj === "Inform & Action") {
          if (this.showAction === "Action-Reach" && this.actionReach === "") {
            return true;
          } else if (
            this.showAction === "Action-Budget" &&
            this.actionBudget === ""
          ) {
            return true;
          }
          budget = this.actionBudget;
          reach = this.actionReach;
        }
        planData = {
          planPreference: this.planPreference,
          budget: budget,
          objective: this.showObj,
          reach: reach,
        };
        this.$emit("handalNextToAssets", planData);
      }
      this.$emit("handalNextToAssets", planData);
    },
    getSelectedPlan(event) {
      this.activeClass = event;
      this.showObj = "";
      this.engage = "";
      this.showInform = "";
      this.showAction = "";
      this.objClass = "";
      this.action = "";
      this.showAllocation = false;
      this.planPreference = event;
    },
    getSelectedOptionObjective(event) {
      this.engage = event;
      this.showInform = "";
      this.showAction = "";
      this.action = "";
      this.objClass = "";
      this.showAllocation = false;
      if (event === "Inform & Engage") {
        this.showObj = event;
      } else {
        this.showObj = event;
      }
    },
    getSelectedOptionInform(event) {
      this.objClass = event;
      if (event === "Reach") {
        this.showInform = "Reach";
      } else {
        this.showInform = "Budget";
      }
    },
    getSelectedOptionInformAction(event) {
      this.action = event;

      if (event === "Reach") {
        this.showAction = "Action-Reach";
        this.showAllocation = false;
      } else {
        this.showAction = "Action-Budget";
        this.showAllocation = true;
      }
    },
    budgetSelect(event) {
      this.showAllocation = true;
      console.log(event);
    },
  },
};
</script>

<style scoped>
.wrapper {
  border: 1px solid;
  border-radius: 10px;
  background-color: #3c4c66;
}
.header {
  padding: 10px;
  font-size: 20px;
  border-bottom: 1px solid;
}
.body {
  padding: 10px;
}
input[type="number"] {
  padding-left: 5px;
  border: 0px;
  border-radius: 5px;
  color: black;
  font-size: 14px;
  height: 30px;
}
.dp-card {
  padding: 2rem;
  width: 80vw;
  background-color: white;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.child-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.v-divider {
  margin-top: 40px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.dp-card-body {
  display: flex;
  justify-content: space-evenly;
  padding: 1rem;
}
.heading {
  font-size: 15px;
  margin: 30px;
}

.budget-card {
  background: #eff5ff;
  padding: 2rem;
  border-width: 0px;
}

.submit-button {
  margin-top: 40px;
  background: none;
  border-radius: 5px;
  color: #fff;
  background: #d92e72;
  display: block;
  font-weight: bold;
  border-radius: 8;
  border-width: 0;
  width: 200px;
  height: 50px;
  font-size: 18px;
}

.submit-button:hover {
opacity: 0.9;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
</style>
