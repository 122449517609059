<template>
  <div>
    <div class="page-wrapper chiller-theme">
      <main
      class="container1"
        style="margin-top: 100px;"
      >
        <div class="">
          <w-digital-planning-options />
        </div>
      </main>
    </div>
  </div>
</template>
<script>
import DigitalPlanningOptions from "./PlanningAssets/DigitalPlanningOptions.vue";

export default {
  props: [],
  components: {
    "w-digital-planning-options": DigitalPlanningOptions,
  },
  data() {
    return {
    };
  },
  methods: {
    
  },
  created() {
  },
};
</script>
<style scoped>

.container1 {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
</style>
