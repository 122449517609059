<template>
  <div>
    <div class="mb-2">
      <div class="btn-back" @click="back">
        <img src="@/assets/Icons/arrow-left-line.svg" alt="back" height="35" />
      </div>
    </div>
    <DigitalPlanningCardComponent title="Campaign Details" />
    <div class="card dp-card" style="margin-bottom:20px">
      <center class="child-container">
        <div class="column">
          <h2 class="section-title">Gender</h2>
        </div>
        <div class="column">
          <h2 class="section-title">Age</h2>
        </div>
        <div class="column">
          <h2 class="section-title">Markets</h2>
        </div>
      </center>
      <div
        class="row"
        :style="
          selectedCampaign === 'TV + Digital'
            ? 'border-right: 1px solid'
            : 'border-left: 0px solid'
        "
      >
        <div class="checkbox-options">
          <div class="column">
            <div class="options">
              <form @submit.prevent>
                <div v-for="(gender, index) in genders" :key="index">
                  <input
                    type="checkbox"
                    style="background-color: #000;"
                    :name="gender.value"
                    :id="gender.value"
                    :value="gender.value"
                    :checked="gender.checked"
                    @click="selectGender($event, gender.name)"
                  />
                  <label :for="gender.value" class="text gender">{{
                    gender.name
                  }}</label
                  ><br />
                </div>
              </form>
            </div>
          </div>
          <div>
            <div class="column">
              <div class="options">
                <form @submit.prevent>
                  <div v-for="(age, index) in ageOption" :key="index">
                    <input
                      type="checkbox"
                      :name="age.value"
                      :id="age.value"
                      :value="age.value"
                      :checked="age.checked"
                      @click="selectAge($event, age.value)"
                    />
                    <label :for="age.value" class="gender text">{{
                      age.name
                    }}</label
                    ><br />
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div v-if="selectedCampaign === 'TV + Digital'">
            <div class="column">
              <div class="title flex">
                <span>TV Market</span> <span class="mr-5">GRP</span>
              </div>
              <div class="options">
                <form @submit.prevent>
                  <div v-for="(market, index) in selectTvMarket" :key="index">
                    <div>
                      <input
                        type="checkbox"
                        :id="'tvonly' + market.name"
                        :name="market.name"
                        :value="market.name"
                        @click="selectedTvMarketList(market.name, $event)"
                      />
                      <label :for="'tvonly' + market.name">{{
                        market.name
                      }}</label>
                    </div>
                    <div>
                      <input
                        type="number"
                        :name="market.name"
                        :value="market.value"
                        @input="getTvMarketValue($event, market.name)"
                        :disabled="!selectedtvMarket.includes(market.name)"
                        :class="{
                          disableInput: !selectedtvMarket.includes(market.name),
                        }"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div v-if="selectedCampaign === 'TV + Digital'">
            <div class="column">
              <div class="title flex">
                <span>Frequency</span> <span class="mr-3">Reach</span>
              </div>
              <div class="options">
                <form @submit.prevent>
                  <div
                    v-for="(frequency, index) in frequencyDetails"
                    :key="index"
                  >
                    <div>
                      <input
                        type="checkbox"
                        :id="frequency.name"
                        :name="frequency.value"
                        :value="frequency.value"
                        @click="selectFrequency($event, frequency)"
                      />
                      <label :for="frequency.name">{{ frequency.name }}</label>
                    </div>
                    <div>
                      <input
                        type="number"
                        :name="frequency.value"
                        :value="frequency.value"
                        :disabled="!selectedFrequency.includes(frequency.name)"
                        :style="
                          !selectedFrequency.includes(frequency.name)
                            ? 'color: transparent;'
                            : 'color: black;'
                        "
                        @input="getFrequencyValue($event, frequency.name)"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div v-if="selectedCampaign === 'TV + Digital'">
            <div class="column">
              <div class="title flex">
                <span
                  >Digital Market (<span
                    @click="chnageDigitalMarket(tvMarketShow)"
                    class="hiper-link ml-1"
                    >Change</span
                  >)</span
                >
                <span class="mr-3">Priority</span>
              </div>
              <div class="options">
                <div
                  v-if="
                    selectDigitalMarket.length === 0 &&
                      selectedtvMarket.length === 0
                  "
                >
                  <span class="title">Please select TV markets</span>
                </div>
                <div
                  v-if="
                    selectDigitalMarket.length === 0 &&
                      selectedtvMarket.length !== 0
                  "
                >
                  <span class="title"
                    >Do you want digital markets same as TV markets</span
                  >
                  <div class="mt-4" style="text-align: center">
                    <button class="btn btn-danger" @click="defaultMarket">
                      No
                    </button>
                    <button class="btn btn-primary ml-4" @click="tvMarket">
                      Yes
                    </button>
                  </div>
                </div>
                <form @submit.prevent>
                  <div
                    v-for="(market, index) in selectDigitalMarket"
                    :key="index"
                    style="display: flex; justify-content: space-between"
                  >
                    <div>
                      <input
                        type="checkbox"
                        :id="'digital' + market.name"
                        :name="market.name"
                        :value="market.name"
                        :checked="market.checked"
                        @click="selectDigitalTvMarketList($event, market.name)"
                      />
                      <label
                        :for="'digital' + market.name"
                        class="gender text"
                        >{{ market.name }}</label
                      >
                    </div>
                    <div>
                      <!--<input
                        type="number"
                        :name="market.value"
                        style="width: 90%"
                      />-->
                      <w-dropdown
                        :options="priorityOptions"
                        :selectedOption="market.priority"
                        :disableDropdown="
                          !selectedDigitalTvMarket.includes(market.name)
                        "
                        @selectedOption="
                          selectDigitalTvPriority($event, market.name)
                        "
                        :style="
                          !selectedDigitalTvMarket.includes(market.name)
                            ? 'color: transparent'
                            : 'color: black'
                        "
                      ></w-dropdown>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div v-if="selectedCampaign === 'Digital Only'">
            <div class="column">
              <div class="title flex"></div>
              <div class="options">
                <form @submit.prevent>
                  <div
                    v-for="(market, index) in selectDigitalMarketOption"
                    :key="index"
                  >
                    <div>
                      <input
                        type="radio"
                        :id="'digitalonly' + market.value"
                        name="market.value"
                        :value="market.value"
                        :checked="market.checked"
                        :disabled="market.isDisable"
                        @click="selectDigitalMarketList($event, market.name)"
                      />
                      <label
                        :for="'digitalonly' + market.value"
                        class="gender text"
                        >{{ market.name }}</label
                      >
                    </div>
                    <div></div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
    <center>
      <button class="submit-button" @click="next">Continue</button>
    </center>
      </div>
    </div>

    <!--<div class="w-50">
        <button class="btn btn-danger" @click="back">Back</button>
      </div>-->
  </div>
</template>

<script>
import Dropdown from "@/widgets/select.vue";
import DigitalPlanningCardComponent from "@/components/DigitalPlanning/components/DigitalPlanningCardComponent.vue";
export default {
  props: ["selectedCampaign"],
  components: {
    "w-dropdown": Dropdown,
    DigitalPlanningCardComponent,
  },
  data() {
    return {
      selectedDigitalOnlyMarket: "All India",
      priorityOptions: [
        { option: "P1", value: "p1" },
        { option: "P2", value: "p2" },
        { option: "P3", value: "p3" },
      ],
      selectedPriority: "",
      selectedDigitalMarketsPriority: [],

      genders: [
        { name: "Male", value: "male", checked: true },
        { name: "Female", value: "female", checked: true },
      ],
      ageOption: [
        //{ name: "15 - 17", value: "15 - 17", checked: true },
        { name: "18 - 24", value: "18-24", checked: true },
        //{ name: "18+", value: "18+", checked: true },
        { name: "25 - 34", value: "25-34", checked: true },
        { name: "35 - 44", value: "35-44", checked: true },
        { name: "45 - 54", value: "45-54", checked: true },
        { name: "55+", value: "55+", checked: true },
      ],

      frequencyDetails: [
        { name: "5+", value: "70" },
        { name: "10+", value: "80" },
        { name: "15+", value: "65" },
        { name: "20+", value: "89" },
        { name: "25+", value: "80" },
        { name: "30+", value: "90" },
      ],

      selectTvMarket: [
        { name: "All India", value: 0, checked: false },
        { name: "HSM", value: 0, priority: "", checked: false },
        {
          name: "AP/Telangana",
          value: 0,

          checked: false,
        },
        { name: "Karnataka", value: 0, checked: false },
        { name: "Tamilnadu", value: 0, checked: false },
        { name: "Kerala", value: 0, checked: false },
      ],
      selectDigitalMarketOption: [
        {
          name: "All India",
          value: "All India",
          priority: "p1",
          checked: true,
          isDisable: false,
        },
        //{ name: "HSM", value: "HSM", priority: "" ,checked: false, isDisable: true},
        //{ name: "AP/Telangana", value: "AP/Telangana", priority: "",checked: false, isDisable: true },
        //{ name: "Karnataka", value: "Karnataka", priority: "",checked: false, isDisable: true },
        //{ name: "Tamilnadu", value: "Tamilnadu", priority: "",checked: false, isDisable: true },
        //{ name: "Kerala", value: "Kerala", priority: "",checked: false, isDisable: true },
        {
          name: "Top 8 Metros",
          value: "Top 8 Metros",
          priority: "",
          checked: false,
          isDisable: false,
        },
        {
          name: "Top 20 Cities",
          value: "Top 20 Cities",
          priority: "",
          checked: false,
          isDisable: false,
        },
        {
          name: "Top 40 Cities",
          value: "Top 40 Cities",
          priority: "",
          checked: false,
          isDisable: false,
        },
      ],
      selectDigitalMarket: [],
      selectedtvMarket: [],
      selectedGender: "all",
      selectedAge: [],
      tvMarketShow: false,
      selectedFrequency: [],
      selectedFrequencyObject: [],
      selectedDigitalOnlyMarketList: "All India",
      selectedDigitalTvMarket: ["All India"],
      selectedTvMarketObject: [],
    };
  },
  computed: {},
  created() {
    for (var i = 0; i < this.ageOption.length; i++) {
      this.selectedAge.push(this.ageOption[i].value);
    }
  },
  methods: {
    getTvMarketValue(event, name) {
      // this.selectedTvMarketObject = this.selectedTvMarketObject.filter((data) => {
      //   data.name.toLowerCase() === name.toLowerCase()
      // })
      // this.selectedTvMarketObject.push({name: name, value: event.target.value})
      // this.selectTvMarket.forEach((obj,index) => {
      //   if (obj.name.toLowerCase() === name.toLowerCase()) {
      //     this.selectTvMarket[index].value = event.target.value;
      //   }
      // })
      this.selectTvMarket.forEach((obj, index) => {
        if (obj.name === name) {
          this.selectTvMarket[index].value = event.target.value;
        }
      });
      this.selectedTvMarketObject.forEach((obj, index) => {
        if (name === obj.name) {
          this.selectedTvMarketObject[index].value = event.target.value;
        }
      });
      console.log(event.target.value, name);
    },
    selectAge(event, value) {
      if (event.target.checked) {
        this.selectedAge.push(value);
      } else {
        this.selectedAge = this.selectedAge.filter((age) => age !== value);
      }
    },
    selectDigitalTvMarketList(event, market) {
      if (event.target.checked) {
        this.selectedDigitalTvMarket.push(market);
      } else {
        this.selectedDigitalTvMarket = this.selectedDigitalTvMarket.filter(
          (marketName) => marketName !== market
        );
      }
    },
    selectDigitalMarketList(event, market) {
      // if (event.target.checked) {
      //   // if(market === 'All India'){
      //   //   this.selectDigitalMarketOption.forEach((obj,index) => {
      //   //     if(obj.name === 'All India'){
      //   //       this.selectDigitalMarketOption[index].isDisable = false
      //   //     }else{
      //   //       this.selectDigitalMarketOption[index].isDisable = true
      //   //     }
      //   //   })
      //   // }else{
      //   //   this.selectDigitalMarketOption.forEach((obj,index) => {
      //   //     if(obj.name === 'All India'){
      //   //       this.selectDigitalMarketOption[index].isDisable = true
      //   //     }else{
      //   //       this.selectDigitalMarketOption[index].isDisable = false
      //   //     }
      //   //   })
      //   // }
      //   this.selectedDigitalOnlyMarket.push(market);
      //   this.selectedDigitalOnlyMarketList.push({
      //     name: market,
      //     priority: "p1",
      //   });
      // } else {
      //   this.selectedDigitalOnlyMarket = this.selectedDigitalOnlyMarket.filter(
      //     (marketName) => marketName !== market
      //   );
      //   this.selectedDigitalOnlyMarketList = this.selectedDigitalOnlyMarketList.filter(
      //     (marketName) => marketName.name !== market
      //   );
      //   // if(market === 'All India' || this.selectedDigitalOnlyMarket.length === 0){
      //   //  this.selectDigitalMarketOption.forEach((obj,index) => {
      //   //     this.selectDigitalMarketOption[index].isDisable = false;
      //   //     console.log(obj)
      //   //   })
      //   // }

      //   // if(this.selectedDigitalOnlyMarket.length === 0){
      //   //   this.selectDigitalMarketOption.forEach((index) => {
      //   //     this.selectDigitalMarketOption[index].isDisable = false
      //   //   })
      //   // }
      // }
      // this.selectedDigitalOnlyMarket = this.selectedDigitalOnlyMarket.filter(
      //     (marketName) => marketName !== market
      //   );
      //   this.selectedDigitalOnlyMarketList = this.selectedDigitalOnlyMarketList.filter(
      //     (marketName) => marketName.name !== market
      //   );
      console.log(market);
      this.selectedDigitalOnlyMarket = market;
      this.selectedDigitalOnlyMarketList = market;
      this.selectDigitalMarketOption.forEach((obj, index) => {
        if (market === obj.name) {
          this.selectDigitalMarketOption[index].priority = "p1";
        }
      });
    },
    selectFrequency(event, frequency) {
      if (event.target.checked) {
        this.selectedFrequency.push(frequency.name);
        this.selectedFrequencyObject.push(frequency);
      } else {
        this.selectedFrequency = this.selectedFrequency.filter(
          (market) => market !== frequency.name
        );
        this.selectedFrequencyObject = this.selectedFrequencyObject.filter(
          (obj) => obj.name !== frequency.name
        );
      }
    },
    getFrequencyValue(event, name) {
      this.selectedFrequencyObject.forEach((obj, index) => {
        if (obj.name === name) {
          this.selectedFrequencyObject[index].value = event.target.value;
        }
      });
    },
    chnageDigitalMarket(value) {
      //this.selectedDigitalMarketsPriority = [];
      if (value) {
        this.selectedDigitalMarketsPriority = [];
        this.tvMarket();
      } else {
        this.selectedDigitalMarketsPriority = [
          { name: "All India", value: "All India", priority: "p1" },
        ];
        this.defaultMarket();
      }
    },
    back() {
      this.$emit("handalBack", true);
    },
    next() {
      var campData;
      var age = [];
      if (this.selectedAge.length === 5) {
        age = [];
        age.push("all");
      } else {
        age = [];
        age = this.selectedAge;
      }
      if (this.selectedCampaign === "TV + Digital") {
        if (
          this.tvMarketShow &&
          this.selectedDigitalMarketsPriority.length !==
            this.selectedDigitalTvMarket.length
        ) {
          return true;
        }
        var checkTvMarket = [];
        var checkfrequency = [];
        var checkDigitalMarket = [];
        campData = {
          advertisingPlatform: this.selectedCampaign,
          gender: this.selectedGender,
          age: age,
          tvMarket: JSON.stringify(this.selectedTvMarketObject),
          frequency: JSON.stringify(this.selectedFrequencyObject),
          digitalMarket: JSON.stringify(this.selectedDigitalMarketsPriority),
        };
        if (
          this.selectedGender === "" ||
          this.selectedAge.length === 0 ||
          this.selectedTvMarketObject.length === 0 ||
          this.selectedFrequencyObject.length === 0 ||
          this.selectedDigitalMarketsPriority.length === 0
        ) {
          return true;
        } else {
          checkTvMarket = this.selectedTvMarketObject.filter(
            (market) => market.priority === ""
          );
          checkfrequency = this.selectedFrequencyObject.filter(
            (market) => market.priority === ""
          );
          checkDigitalMarket = this.selectedDigitalMarketsPriority.filter(
            (market) => market.priority === ""
          );
        }
        if (
          checkTvMarket.length === 0 ||
          checkfrequency.length === 0 ||
          checkDigitalMarket.length === 0
        ) {
          this.$emit("handalNext", campData);
        }
      } else if (this.selectedCampaign === "Digital Only") {
        //var check = [];
        campData = {
          advertisingPlatform: this.selectedCampaign,
          gender: this.selectedGender,
          age: age,
          //digitalMarket: JSON.stringify(this.selectedDigitalOnlyMarketList),
          digitalMarket: this.selectedDigitalOnlyMarketList,
        };
        if (
          this.selectedGender === "" ||
          this.selectedAge.length === 0 ||
          this.selectedDigitalOnlyMarketList === ""
        ) {
          return true;
        }
        // else {
        //   check = this.selectedDigitalOnlyMarketList.filter(
        //     (market) => market.priority === ""
        //   );
        // }
        //if (check.length === 0) {
        this.$emit("handalNext", campData);
        //}
      }
    },
    tvMarket() {
      this.tvMarketShow = false;
      this.selectedDigitalTvMarket = [];
      this.selectDigitalMarket = [];
      for (var i = 0; i < this.selectedtvMarket.length; i++) {
        this.selectDigitalMarket.push({
          name: this.selectedtvMarket[i],
          value: this.selectedtvMarket[i],
          priority: "p1",
          checked: true,
        });
        this.selectedDigitalMarketsPriority.push({
          name: this.selectedtvMarket[i],
          value: this.selectedtvMarket[i],
          priority: "p1",
        });
        this.selectedDigitalTvMarket.push(this.selectedtvMarket[i]);
      }
    },
    selectGender(event, value) {
      if (event.target.checked) {
        if (
          (this.selectedGender === "Male" && value === "Female") ||
          (this.selectedGender === "Female" && value === "Male")
        ) {
          this.selectedGender = "all";
        } else {
          this.selectedGender = value;
        }
      } else {
        if (value === "Male" && this.selectedGender !== value) {
          this.selectedGender = "Female";
        } else if (value === "Female" && this.selectedGender !== value) {
          this.selectedGender = "Male";
        } else if (value === this.selectedGender) {
          this.selectedGender = "";
        }
      }
    },
    defaultMarket() {
      this.tvMarketShow = true;
      this.selectDigitalMarket = [];
      this.selectedDigitalTvMarket = [];
      this.selectedDigitalTvMarket = ["All India"];
      this.selectDigitalMarket = this.selectDigitalMarketOption;
    },
    selectedTvMarketList(value, event) {
      this.selectDigitalMarket = [];
      if (event.target.checked) {
        this.selectedtvMarket.push(value);
        this.selectedTvMarketObject.push({ name: value, value: 0 });
      } else {
        this.selectedtvMarket = this.selectedtvMarket.filter(
          (market) => market !== value
        );
        this.selectedTvMarketObject = this.selectedTvMarketObject.filter(
          (obj) => obj.name !== value
        );
      }
      return value, event.target.checked;
    },
    selectDigitalTvPriority(event, market) {
      this.selectDigitalMarket.forEach((obj, index) => {
        if (obj.name === market) {
          this.selectDigitalMarket[index].priority = event;
        }
      });
      if (
        this.selectedDigitalMarketsPriority.filter((obj) => obj.name === market)
          .length !== 0
      ) {
        this.selectedDigitalMarketsPriority = this.selectedDigitalMarketsPriority.filter(
          (obj) => obj.name !== market
        );
        this.selectedDigitalMarketsPriority.push({
          name: market,
          value: market,
          priority: event,
        });
      } else {
        this.selectedDigitalMarketsPriority.push({
          name: market,
          value: market,
          priority: event,
        });
      }
    },
    // selectDigitalOnlyPriority(event, market) {
    //   if (
    //     this.selectedDigitalOnlyMarketList.filter(
    //       (priority) => priority.name === market
    //     ).length !== 0
    //   ) {
    //     // this.selectedDigitalOnlyMarketList = this.selectedDigitalOnlyMarketList.filter(
    //     //   (priority) => priority.name !== market
    //     // );
    //     this.selectedDigitalOnlyMarketList.push({
    //       name: market,
    //       priority: event,
    //     });
    //   } else {
    //     this.selectedDigitalOnlyMarketList.push({
    //       name: market,
    //       priority: event,
    //     });
    //   }
    //   this.selectDigitalMarketOption.forEach((option, index) => {
    //     if (option.name === market) {
    //       this.selectDigitalMarketOption[index].priority = event;
    //     }
    //   });
    // },
  },
};
</script>

<style scoped>
.form-control {
  height: 28px;
  padding: 0rem 0.75rem;
}
.column {
  padding: 1rem;
}
.gender {
  padding-left: 5px;
}

.child-container {
  display: flex;
  background: #d92e72;
  border-radius: 10px;
  justify-content: space-evenly;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.input-column {
  display: flex;
  flex-direction: column;
  margin-top: 26px;
  padding-left: 5rem;
}

.title {
  margin-bottom: 30px;
  font-size: 18px;
  font-family: "Muli", sans-serif;
}
.text {
  font-size: 16px;
  margin: 1.5rem;
}
input[type="number"] {
  padding-left: 5px;
  border: 0px;
  border-radius: 5px;
  color: black;
  font-size: 14px;
}
.disableInput {
  cursor: not-allowed;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #3b475a inset !important;
  box-shadow: 0 0 0 30px #3b475a inset;
}

.options-container {
  display: flex;
  flex-direction: row;
}

.dp-card {
  padding: 2rem;
  width: 80vw;
  background-color: white;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.checkbox-options {
  display: flex;
  justify-content: space-evenly;
}

.submit-button {
  margin-top: 40px;
  background: none;
  border-radius: 5px;
  color: #fff;
  background: #d92e72;
  display: block;
  font-weight: bold;
  border-radius: 8;
  border-width: 0;
  width: 200px;
  height: 50px;
  font-size: 18px;
}

.submit-button:hover {
  opacity: 0.9;
}

.section-title {
 color: #fff;
}
</style>
