<template>
  <select
    class="form-control"
    @change="selectedoption"
    v-model="selectedOption"
    :disabled="disableDropdown"
    autocomplete="new-country"
  >
    <option
      value=""
      selected=""
      disabled="disabled"
      :style="!disabledOption ? 'display: none' : 'display: block'"
    >
      {{ disabledOption }}
    </option>
    <option
      v-for="option in options"
      :value="option.value"
      :key="option"
      :disabled="option.disabled"
    >
      {{ option.option }}
    </option>
  </select>
</template>
<script>
export default {
  props: ["options", "selectedOption", "disabledOption", "disableDropdown"],
  data: function () {
    return {};
  },
  methods: {
    selectedoption() {
      this.$emit("selectedOption", this.selectedOption);
    },
  },
};
</script>