<template>
  <div class="card dp-card1">
    <h1 class="card-title">
      {{ title }}
    </h1>
  </div>
</template>

<script>
export default {
  name: "DigitalPlanningCardComponent",
  props: {
    title: String,
  },
};
</script>

<style scoped>
.container {
  border-radius: 0px;
}

.card-title {
  font-size: 24px;
  font-weight: bold;
  font-family: ProximaNovaBold;
  color: black;
}

.card {
  background-color: white;
  border-radius: 0px;
}

.dp-card1 {
  padding: 2rem;
  width: 80vw;
  background-color: white;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
</style>