<template>
  <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12">
    <div v-if="showBlock == 'showDigitalPlanningOptions'" style="margin-top: 80px">
      <DigitalPlanningCardComponent title="Digital Planning : Select Objective" />
      <div class="card dp-card">
        <div class="container">
          <div v-for="(option, index) in dpOptions" :key="index">
            <w-digital-planning-card :isActive="activeClass" :option="option"
              @optionClick="getSelectedOptionCampaign($event)" />
          </div>
        </div>
        <center>
          <button class="submit-button" @click="gotoCampaign">Continue</button>
        </center>
      </div>
    </div>
    <div class="v-divider" />
    <div v-if="selectJourney" class="d-none">
      <DigitalPlanningCardComponent title="Advertising Platforms For The Campaign" />
      <div class="card dp-card">
        <div class="container">
          <div class="options-container" v-for="(option, index) in campaignPlatformOption" :key="index">
            <w-digital-planning-card :isActive="activeCampaignType" :option="option"
              @optionClick="getSelectedOption($event)" />
          </div>
        </div>
      </div>
      <center class="v-divider">
        <span class="header" v-if="abort"><b>Note : </b>TV + Digital is not available right now</span>
      </center>
    </div>
    <div :style="
      !selectJourney && showBlock == 'addCampaignDetails'
        ? 'display: block'
        : 'display: none'
    ">
      <w-add-campaign-details @handalBack="backHandal" @handalNext="handalNext" :selectedCampaign="campaignName">
      </w-add-campaign-details>
    </div>
    <div :style="
      showBlock === 'showPlanPreference' ? 'display: block' : 'display: none'
    ">
      <w-add-plan-preference @handalNextToAssets="handalNextToAssets" @backToCampaignDetails="backToCampaignDetails">
      </w-add-plan-preference>
    </div>
    <div :style="
      showBlock === 'addAviableAssets' ? 'display: block' : 'display: none'
    ">
      <w-add-aviable-assets @handalNextBrand="handalNextBrand" @backToPlanPreference="backToPlanPreference">
      </w-add-aviable-assets>
    </div>
    <div :style="
      showBlock === 'addBrandsDetails' ? 'display: block' : 'display: none'
    ">
      <w-add-brands-details @backAvailableAssets="backAvailableAssets" @submitData="connectorsSummary"
        :brandCat="brandCat"></w-add-brands-details>
    </div>
    <!--   <div :style="showBlock === 'learning' ? 'display: block' : 'display: none'">
      <p-learning
        :progressTest="progressTest"
        :progressTrain="progressTrain"
        :progressLearn="progressLearn"
        :active="active"
        :learnSlide="learnSlide"
        :trainSlide="trainSlide"
        :testSlide="testSlide"
      ></p-learning> 
    </div>-->
    <div :style="
      showBlock === 'connectorsSummary' ? 'display: block' : 'display: none'
    ">
      <p-connector-summary @back="backToBrand" @gotoLearning="gotoLearning"></p-connector-summary>
    </div>
    <div :style="
      showBlock === 'planningDashboard' ? 'display: block' : 'display: none'
    ">
      <w-planning-dashboard :isTableLoding="isTableLoding" :tableDataValues="tableDataValues"
        @backToPreviousPage="backToPreviousPage" :adTypeWiseBudgetSplit="adTypeWiseBudgetSplit"
        :channelWiseImpressionSplit="channelWiseImpressionSplit" :platformWiseBudgetSplit="platformWiseBudgetSplit"
        :platformWiseOptions="platformWiseOptions" :reachWiseBudgetSplit="reachWiseBudgetSplit" :category="categoryName"
        @selecctedPlatform="selecctedPlatform" @selectedChannel="selectedChannel" :totalData="totlaData">
      </w-planning-dashboard>
    </div>
    <!--<w-confirmation-block v-if="showBlock == 'showPreviousHistoryConfirmation'" @clickedConfirmationBtn="handleConfirmation($event)"/>-->
    <div class="mt-3 text-right" v-if="showBlock == 'addBusinessDetails'">
      <button class="btn btn-warning cancel-btn" @click="handleCancel()">
        Cancel
      </button>
      <button :disabled="disableSubmit" class="submit-btn" @click="showAddBrandDetails()">
        Next
      </button>
    </div>
    <div class="mt-3 text-right" v-if="showBlock == 'showAddBrandDetails'">
      <button class="btn btn-warning cancel-btn" @click="handleCancel()">
        Cancel
      </button>
      <button :disabled="disableBrandSubmit" class="submit-btn" @click="showTableDetails($event)">
        Submit
      </button>
    </div>
    <page-loader v-show="pageLoader"></page-loader>
  </div>
</template>
<script>
const baseurl = "http://1874-3-88-1-117.ngrok.io/";
var timer;
import DigitalPlanningCard from "@/widgets/DigitalPlanningCard.vue";
//import PreviousHistoryConfirmation from "@/components/DigitalPlanning/PreviousHistoryConfirmation.vue";
import CampaignDetails from "./AddCampaignDetails.vue";
import PlanPreference from "./AddPlanPreference.vue";
import AviableAssets from "./AddAvailableAssets.vue";
import AddBrandsDetails from "./AddBrandsDetails.vue";
import PlanningDashboard from "./PlanningDashboard.vue";
import { DigitalPlanning } from "@/services/DigitalPlanningServices.js";
// import Learning from "./PlanLearning.vue";
import Summary from "./ConnectorSummary.vue";
import PageLoader from "@/widgets/PageLoader.vue";
import Axios from "axios";
import DigitalPlanningCardComponent from "@/components/DigitalPlanning/components/DigitalPlanningCardComponent.vue";
import { abbreviateNumber } from "js-abbreviation-number";
const digitalPlanning = new DigitalPlanning();

export default {
  components: {
    // "p-learning": Learning,
    "p-connector-summary": Summary,
    "w-digital-planning-card": DigitalPlanningCard,
    //"w-confirmation-block": PreviousHistoryConfirmation,
    "w-add-campaign-details": CampaignDetails,
    "w-add-plan-preference": PlanPreference,
    "w-add-aviable-assets": AviableAssets,
    "w-add-brands-details": AddBrandsDetails,
    "w-planning-dashboard": PlanningDashboard,
    PageLoader,
    DigitalPlanningCardComponent,
  },
  data() {
    return {
      totlaData: {},
      brandCat: [],
      progressLearn: 0,
      progressTest: 0,
      progressTrain: 0,
      active: "learn",
      learnSlide: "",
      testSlide: "slide3",
      trainSlide: "slide2",
      categoryName: "",
      showBlock: "showDigitalPlanningOptions",
      //showBlock: "addCampaignDetails",
      dpOptions: [
        //"New Product Launch",
        //"New brand /Sub brand Launch",
        "Action",
        "Engagement",
        "Inform/Enable",
        //"Performance + Branding",
      ],
      pieLegend: {
        layout: "vertical",
        align: "right",
        verticalAlign: "middle",
        itemMarginTop: 10,
        itemMarginBottom: 10,
      },
      adTypeWiseBudgetSplit: [
        {
          name: "AdType",
          colorByPoint: true,
          data: [],
          colors: [
            "#d92e72",
            "#b5a9ff",
            "#ffdd7c",
            "#faacfe",
            "#81f89e",
            "#ffa2a2",
          ],
        },
      ],
      platformWiseOptions: [],
      platformWiseBudgetSplit: {
        //scrollableWidth: 1200,
        data: [
          {
            name: "",
            data: [],
          },
        ],
        title: {
          text: "",
          align: "left",
          margin: 50,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        color: [
          "#d92e72",
          "#b5a9ff",
          "#ffdd7c",
          "#faacfe",
          "#81f89e",
          "#ffa2a2",
        ],
        legend: {
          enabled: false,
        },
        xAxis: {
          categories: [],
          crosshair: {
            enabled: true,
          },
          scrollbar: {
            enabled: true,
            barBackgroundColor: "#ccc",
            barBorderRadius: 7,
            barBorderWidth: 0,
            buttonBackgroundColor: "white",
            buttonBorderWidth: 0,
            buttonArrowColor: "white",
            buttonBorderRadius: 0,
            rifleColor: "#F7FAFF",
            trackBackgroundColor: "white",
            trackBorderWidth: 0,
            trackBorderColor: "#FAFAFA",
            trackBorderRadius: 0,
            width: 50,
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: {
          visible: true,
          min: 0,
          title: {
            text: "",
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          lineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "14px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        plotOptions: {
          column: {
            // borderWidth: 0,
            // colorByPoint: true,
            dataLabels: {
              enabled: true,
            },
          },
          series: {
            dataLabels: {
              enabled: true,
              formatter: function () {
                return abbreviateNumber(this.y, 1, {
                  symbols: ["", "k", "M", "B", "T", "P", "E"],
                });
              },
            },
            // borderRadiusTopLeft: 25,
            // borderRadiusTopRight: 25,
          },
        },
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
        },
      },
      channelWiseImpressionSplit: {
        data: [
          {
            name: "Channel wise impression split",
            data: [],
          },
        ],
        title: {
          text: "",
          align: "left",
          margin: 50,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        color: ["#d92e72", "#b5a9ff", "#81f89e", "#ffdd7c", "#ffa2a2"],
        legend: {
          enabled: false,
        },
        xAxis: {
          // max: 4,
          categories: [],
          crosshair: {
            enabled: true,
          },
          scrollbar: {
            enabled: true,
            barBackgroundColor: "#ccc",
            barBorderRadius: 7,
            barBorderWidth: 0,
            buttonBackgroundColor: "white",
            buttonBorderWidth: 0,
            buttonArrowColor: "white",
            buttonBorderRadius: 0,
            rifleColor: "#F7FAFF",
            trackBackgroundColor: "white",
            trackBorderWidth: 0,
            trackBorderColor: "#FAFAFA",
            trackBorderRadius: 0,
            width: 50,
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: {
          visible: true,
          min: 0,
          title: {
            text: "",
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          lineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "14px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        plotOptions: {
          column: {
            // borderWidth: 0,
            // colorByPoint: true,
            dataLabels: {
              enabled: true,
            },
          },
          series: {
            dataLabels: {
              enabled: true,
              formatter: function () {
                return abbreviateNumber(this.y, 1, {
                  symbols: ["", "k", "M", "B", "T", "P", "E"],
                });
              },
            },
            // borderRadiusTopLeft: 25,
            // borderRadiusTopRight: 25,
          },
          // },
        },
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
        },
      },
      tableDataValues: {
        fields: [
          {
            key: "rowDetails",
            label: "Platform Genere By AdType",
            thClass: "thClassLeft",
            tdClass: "text-left",
          },
          {
            key: "website",
            label: "Website",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "adType",
            label: "AdType",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "impressions",
            label: "Impressions",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "clicks",
            label: "Clicks",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "videoViews",
            label: "Video Views",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "engagements",
            label: "Engagements",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "reach",
            label: "Reach",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "cpm",
            label: "CPM",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "cost",
            label: "Cost",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },

          // {
          //   key: "platFormGenereByAdType",
          //   label: "Plat Form Genere By AdType",
          //   thClass: "thClassLeft",
          //   tdClass: "text-left",
          //   sortable: false,
          // },

          // {
          //   key: "editRow",
          //   label: "Action",
          //   thClass: "thClassLeft",
          //   tdClass: "text-left",
          //   sortable: false,
          // },
        ],
        items: [],
      },
      submitOverAllData: {
        campData: {},
        planData: {},
        assetData: {},
        businessTypeData: {},
      },
      disableSubmit: true,
      selectJourney: false,
      campaignName: "Digital Only",
      abort: false,
      campaignPlatformOption: ["TV + Digital", "Digital Only"],
      disableBrandSubmit: true,
      campaignGoal: "",
      brandUSP: "",
      businessType: "",
      brandCategory: "",
      brandStrength: "",
      userId: "",
      datastreamId: "",
      businessDetail: "",
      brandDetail: "",
      addbranddetailcategeory: "",
      getUserID: "",
      getPlanname: "",
      getUserId: "",
      subbrandcategeory: "",
      errors: [],
      brandName: "",
      brandKeyWords: [],
      categoryKeyWords: [],
      subbrandKeyWords: [],
      competitorNames: [],
      activeClass: "",
      activeCampaignType: "",
      isTableLoding: true,
      tableDataValuesItem: [],
      reachWiseBudgetSplit: [
        {
          name: "reachWise",
          colorByPoint: true,
          data: [],
          colors: [
            "#d92e72",
            "#b5a9ff",
            "#ffdd7c",
            "#faacfe",
            "#81f89e",
            "#ffa2a2",
          ],
        },
      ],
    };
  },
  beforeDestroy() {
    clearInterval(timer);
  },
  computed: {
    currentRouteName() {
      return console.log(this.$route.name);
    },
    pageLoader() {
      if (this.showBlock === "planningDashboard") {
        if (
          this.tableDataValues.items.length > 0 &&
          this.adTypeWiseBudgetSplit[0].data.length > 0 &&
          this.channelWiseImpressionSplit.data[0].data.length > 0 &&
          this.platformWiseBudgetSplit.data[0].data.length > 0 &&
          this.reachWiseBudgetSplit[0].data.length > 0
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
  },
  methods: {
    getBrandCategory() {
      digitalPlanning
        .getBrandCate(sessionStorage.getItem("clientId"))
        .then((res) => {
          for (var i = 0; i < res[0].allCategories.length; i++) {
            this.brandCat.push({
              name: res[0].allCategories[i],
              value: res[0].allCategories[i],
            });
          }
        });
    },
    connectorsSummary(event) {
      this.showBlock = "connectorsSummary";
      this.submitOverAllData.businessTypeData = event;
      this.categoryName = event.brandCategory;
    },
    submitData() {
      //this.submitOverAllData.businessTypeData = event;
      this.totlaData = {
        ...this.submitOverAllData.campData,
        ...this.submitOverAllData.planData,
        ...this.submitOverAllData.assetData,
        ...this.submitOverAllData.businessTypeData,
        digitalPLanType: this.activeClass,
      };

      localStorage.setItem("DigitalPlanning", JSON.stringify(this.totlaData))

      if (this.$route.name === "Digital Planning") {
        digitalPlanning.digitalPlanGeneration(this.totlaData).then((res) => {
          this.getDigitalPlanningTableData(res);
        });
      }

      else if (this.$route.name === "SavedDigitalPlans") {

        var orgId = sessionStorage.getItem("orgId")

        digitalPlanning.getSavedDigitalPlans(orgId).then((res) => {
          digitalPlanning.digitalPlanGeneration(res).then((res) => {
            this.getDigitalPlanningTableData(res);

          });
        })


      }

      this.showBlock = "planningDashboard";
    },
    getDigitalPlanningTableData(id) {
      this.reachWiseBudgetSplit[0].data = [];
      this.channelWiseImpressionSplit.data[0].data = [];
      this.adTypeWiseBudgetSplit[0].data = [];
      // this.reachWiseBudgetSplit = [];
      this.tableLoding = true;
      digitalPlanning.getDigitalPlanningData(id).then((res) => {
        //this.pageLoad = false
        this.isTableLoding = false;
        this.tableDataValues.items = [];
        this.tableDataValues.items = res;
        this.getPlateformWiseBudgetSplit("all");
        this.getAdtypeWiseBudgetSplit("all");
        this.getChannelWiseBudgetSplit("impressions");
        this.getReachWiseBdgetSplit("reach");
      });
    },
    getPlateformWiseBudgetSplit(type) {
      digitalPlanning.getPlatformWiseBudget(type).then((res) => {
        this.platformWiseBudgetSplit.data[0].data = [];
        this.platformWiseBudgetSplit.xAxis.categories = [];
        if (type === "all") {
          // this.platformWiseOptions = [];
          this.platformWiseOptions = [{ id: "All", text: "all" }];
          for (var i = 0; i < res.length; i++) {
            this.platformWiseOptions.push({
              id: res[i].name,
              text: res[i].name,
            });
          }
        }
        for (var j = 0; j < res.length; j++) {
          this.platformWiseBudgetSplit.data[0].data.push(res[j].value);
          this.platformWiseBudgetSplit.xAxis.categories.push(res[j].name);
        }
      });
    },
    getAdtypeWiseBudgetSplit(type) {
      digitalPlanning.getAdtypeWiseBudget(type).then((res) => {
        this.adTypeWiseBudgetSplit[0].data = [];
        for (var i = 0; i < res.length; i++) {
          if (i === 0) {
            this.adTypeWiseBudgetSplit[0].data.push({
              name: res[i].name,
              y: res[i].value,
              sliced: true,
              selected: true,
            });
          } else {
            this.adTypeWiseBudgetSplit[0].data.push({
              name: res[i].name,
              y: res[i].value,
            });
          }
        }
      });
    },
    getChannelWiseBudgetSplit(type) {
      digitalPlanning.getChannelWiseBudget(type).then((res) => {
        this.channelWiseImpressionSplit.data[0].data = [];
        this.channelWiseImpressionSplit.xAxis.categories = [];

        for (var i = 0; i < res.length; i++) {
          if (res[i].annotation === "M") {
            this.channelWiseImpressionSplit.data[0].data.push(res[i].value);
            this.channelWiseImpressionSplit.xAxis.categories.push(res[i].name);
          } else if (res[i].annotation === "K") {
            this.channelWiseImpressionSplit.data[0].data.push(res[i].value);
            this.channelWiseImpressionSplit.xAxis.categories.push(res[i].name);
          }
        }
      });
    },
    getReachWiseBdgetSplit(type) {
      digitalPlanning.getChannelWiseBudget(type).then((res) => {
        this.reachWiseBudgetSplit[0].data = [];
        // this.reachWiseBudgetSplit.push(["Channel", "Value (in millions)"]);
        for (var i = 0; i < res.length; i++) {
          if (res[i].annotation === "K") {
            this.reachWiseBudgetSplit[0].data.push({
              name: res[i].name,
              y: res[i].value,
              sliced: true,
              selected: true,
            });
          } else if (res[i].annotation === "M") {
            this.reachWiseBudgetSplit[0].data.push({
              name: res[i].name,
              y: res[i].value,
            });
          } else {
            this.reachWiseBudgetSplit[0].data.push({
              name: res[i].name,
              y: res[i].value,
            });
          }
        }
      });
    },
    backToPreviousPage() {
      this.showBlock = "addBrandsDetails";
      //this.showBlock = "addAviableAssets";
    },
    backToCampaignDetails() {
      this.showBlock = "addCampaignDetails";
    },
    backToPlanPreference() {
      this.showBlock = "showPlanPreference";
    },
    backAvailableAssets() {
      this.showBlock = "addAviableAssets";
    },
    selecctedPlatform(event) {
      this.getPlateformWiseBudgetSplit(event);
    },
    selectedChannel(event) {
      this.getChannelWiseBudgetSplit(event);
    },
    backToBrand() {
      this.showBlock = "addBrandsDetails";
    },
    gotoLearning() {
      // this.showBlock = "showoverallView";
      // this.progress();
      this.submitData();
    },
    progress() {
      var self = this;
      var count = 0;
      self.progressLearn = 0;
      self.progressTest = 0;
      self.progressTrain = 0;
      self.active = "learn";
      self.learnSlide = "";
      self.testSlide = "slide3";
      self.trainSlide = "slide2";
      timer = setInterval(function () {
        if (count == 0) {
          if (self.progressLearn == 100) {
            self.learnSlide = "slide11";
            self.testSlide = "slide33";
            count++;
          } else {
            ++self.progressLearn;
          }
        } else if (count == 1) {
          self.active = "train";
          if (self.progressTrain == 100) {
            self.learnSlide = "slide111";
            self.trainSlide = "slide222";
            count++;
          } else {
            ++self.progressTrain;
          }
        } else if (count == 2) {
          self.active = "test";
          self.learnSlide = "slide111";
          if (self.progressTest == 100) {
            clearInterval(timer);
            self.submitData();
            //self.$router.push("/digital-planning/performance/dashboard")
          } else {
            ++self.progressTest;
          }
        }
      }, 50);
    },
    handalNextBrand(event) {
      this.submitOverAllData.assetData = event;
      //this.submitData()
      this.showBlock = "addBrandsDetails";
    },
    // editItem(id, updatedItem) {
    //     this.employees = this.employees.map(employee =>
    //     employee.id === id ? updatedEmployee : employee)
    // },
    getSelectedOptionCampaign(selectedOption) {
      if (
        selectedOption === "Action" ||
        selectedOption === "Engagement" ||
        selectedOption === "Inform/Enable"
      ) {
        //this.showBlock = "showPreviousHistoryConfirmation";
        this.selectJourney = true;
        this.activeClass = selectedOption;
      }
    },
    handalNextToAssets(planData) {
      this.showBlock = "addAviableAssets";
      this.submitOverAllData.planData = planData;
    },
    gotoCampaign() {
      if (
        this.campaignName !== "TV + Digital" &&
        this.campaignName !== "Digital Only"
      ) {
        return true;
      }
      if (
        this.campaignName === "Digital Only" &&
        this.activeClass === "Performance (sales or leads)"
      ) {
        this.$router.push("/digital-planning/performance");
      } else {
        this.showBlock = "addCampaignDetails";
        this.selectJourney = false;
      }
    },
    getSelectedOption(selectedOption) {
      //this.showBlock = "showPreviousHistoryConfirmation";
      //this.showBlock = "addBusinessDetails";

      // if(this.activeClass === 'Performance (sales or leads)' && selectedOption === 'Digital Only'){
      //   //this.$router.push('/digital-planning/performance')
      //   console.log('performance')
      // }else{
      if (selectedOption === "TV + Digital") {
        this.abort = true;
        this.campaignName = "";
        this.activeCampaignType = "";
      } else {
        this.abort = false;
        //this.showBlock = "addCampaignDetails";
        //this.selectJourney = false;
        this.campaignName = selectedOption;
        this.activeCampaignType = selectedOption;
        //}
      }
    },
    backHandal(event) {
      this.showBlock = "showDigitalPlanningOptions";
      this.selectJourney = true;
      console.log(event);
    },
    handalNext(event) {
      this.showBlock = "showPlanPreference";
      this.submitOverAllData.campData = event;
    },
    userInput(data) {
      this.competitorNames = data.competitionKeyWords;
      this.categoryKeyWords = data.categoryKeyWords;
      this.brandKeyWords = data.brandKeyWords;
      this.subbrandKeyWords = data.subbrandKeyWords;
      console.log(data);
    },
    userbrandName(data) {
      console.log(data);
      this.brandName = data.brand;
    },
    handleConfirmation(data) {
      if (data.submit) {
        if (data.hasPrevAdsHistory) {
          this.showBlock = "selectChannel";
        } else {
          this.showBlock = "addBusinessDetails";
        }
      } else {
        this.showBlock = "showDigitalPlanningOptions";
      }
    },
    handleSelectedOptions(data) {
      console.log("Data ", data);
      if (
        data.selectedBrandStrength != "" &&
        data.selectedCampaignGoalOption != "" &&
        data.selectedUsp != "" &&
        data.selectedBusinessType != "" &&
        data.selectedCategory != "" &&
        data.selectedSubCategory != ""
      ) {
        this.disableSubmit = false;
        this.campaignGoal = data.selectedCampaignGoalOption;
        this.brandUSP = data.selectedUsp;
        this.businessType = data.selectedBusinessType;
        this.brandCategory = data.selectedCategory;
        this.brandStrength = data.selectedBrandStrength;
        this.addbranddetailcategeory = data.selectedCategory;
        this.subbrandcategeory = data.selectedSubCategory;
      } else {
        this.disableSubmit = true;
      }
    },
    showSubmit(data) {
      if (data.showSubmit === "SUCCESS") {
        this.disableBrandSubmit = false;
      } else {
        this.disableBrandSubmit = true;
      }
      console.log("showSubmit ", data);
    },
    // handleSelectedCategory(data){
    //     console.log(data)

    //     this.subbrandcategeory = data.selectedCategory;

    //     if(data.selectedCategory !=""){
    //         this.disableBrandSubmit  = false;
    //     }else{
    //         this.disableBrandSubmit = true;
    //     }
    // },
    handleCancel() {
      this.showBlock = "showDigitalPlanningOptions";
    },
    showAddBrandDetails() {
      Axios.post(baseurl + "api/digitalplan/business-details", {
        businessDetail: {
          campaignGoal: this.campaignGoal,
          brandUSP: this.brandUSP,
          businessType: this.businessType,
          brandCategory: this.brandCategory,
          brandStrength: this.brandStrength,
          brandSubCategory: this.subbrandcategeory,
        },
        userId: "bharath@cognitiveroi.com",
        datastreamId: "357",
      })
        .then((response) => {
          this.userId = response.data.response.userId;
          this.getPlanname = response.data.response.planName;
          this.getUserID = response.data.response.userId;
          this.getUserId = response.data.response.userId;
        })
        .catch((e) => {
          this.errors.push(e);
        });
      this.showBlock = "showAddBrandDetails";
    },
    showTableDetails(option) {
      this.showBlock = "showoverallView";
      console.log("OPtions ", option);
      //     Axios.post(baseurl+"api/digitalplan/brand-details",{
      //         brandDetail: {
      //         brandName: this.brandName,
      //         brandKeywords: this.brandKeyWords.toString(),
      //         brandCategory: this.subbrandcategeory,
      //         categoryKeywords: this.categoryKeyWords.toString(),
      //         competitorNames: this.competitorNames.toString(),
      //         competitorKeywords: this.subbrandKeyWords.toString()
      //         },
      //         userId: this.userId,
      //         datastreamId: "357"
      //     }
      //     )
      //     .then(response => {console.log(response)
      //     this.getUserId = response.data.response.userId;
      //     this.getPlanname = response.data.response.planName;
      //     this.showBlock = "showoverallView";})
      //     .catch(e => {
      //     this.errors.push(e)
      // })
    },
    saveDataToLocal() {
      // localStorage.setItem("DigitalPlanning", JSON.stringify(this.totlaData))
      var parsedobj = JSON.parse(JSON.stringify(this.totlaData));
      console.log(parsedobj);
    },
  },
  created() {
    //this.getBrandCategory()
  },
};
</script>
<style scoped>
.card-header {
  background: transparent;
  border-bottom: 1px solid #ffffff;
}

.cancel-btn {
  height: 50px;
  border: 1px solid #ffffff;
  border-radius: 5px;
  color: #ffffff;
  text-transform: uppercase;
  padding: 0 50px;
  margin-right: 10px;
}

.cancel-btn:focus,
.submit-btn:focus {
  outline: 0;
  box-shadow: none;
}

.submit-btn {
  height: 50px;
  border: 1px solid #ff0042;
  background-color: #ff0042;
  border-radius: 5px;
  color: #ffffff;
  text-transform: uppercase;
  padding: 0 50px;
}

.submit-btn:disabled {
  border: 1px solid #ff004250;
  background-color: #ff004250;
  color: #ffffff50;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.card {
  background-color: #ffffff;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2);
}

.dp-card {
  padding: 2rem;
  width: 80vw;
  background-color: white;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.v-divider {
  margin-top: 30px;
}

.options-container {
  display: flex;
  flex-direction: row;
  padding: 1rem;
}

.submit-button {
  margin-top: 40px;
  background: none;
  border-radius: 5px;
  color: #fff;
  background: #d92e72;
  display: block;
  font-weight: bold;
  border-radius: 8px;
  border-width: 0;
  width: 200px;
  height: 50px;
  font-size: 18px;
}

.submit-button:hover {
  opacity: 0.9;
}

.header {
  font-size: 18px;
  color: #000;
}
</style>
